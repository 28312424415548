<template>
  <b-container
    class="p-0"
    fluid
  >
    <ValidationObserver
      ref="formItem"
    >
      <form
        novalidate
        @submit.prevent
      >
        <b-row
          class="border-bottom py-4"
        >

          <b-col
            cols="12"
            md="4"
          >
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="purchase date"
                  :vid="vPURCHASEDATE"
                  rules="required"
                >
                  <b-form-group>
                    <label for="item_purchase_date">
                      <strong>
                        Purchase Date
                      </strong>
                    </label>
                    <b-input
                      id="item_purchase_date"
                      v-model="composition.purchase_date"
                      type="date"
                      placeholder="enter purchase date"
                      autocomplete="off"
                      :state="errors.length > 0 ? false : null"
                      :disabled="updating || state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="original cost"
                  :vid="vORIGINALCOST"
                  rules="required"
                >
                  <b-form-group>
                    <label for="item_original_cost">
                      <strong>
                        Cost
                      </strong>
                    </label>
                    <b-input
                      id="item_original_cost"
                      v-model="composition.original_cost"
                      type="number"
                      placeholder="enter original cost"
                      autocomplete="off"
                      step="0.01"
                      :state="errors.length > 0 ? false : null"
                      :disabled="updating || state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <ValidationProvider
              #default="{ errors }"
              name="supplier"
              :vid="vSUPPLIER"
              rules="required"
            >
              <b-form-group>
                <label for="item_supplier">
                  <strong>
                    Supplier
                  </strong>
                </label>
                <v-select
                  id="item_supplier"
                  v-model="selected.supplier"
                  type="text"
                  label="supplier_name"
                  placeholder="search supplier here"
                  :options="suppliers"
                  :class="[errors.length > 0 ? 'is-invalid' : null]"
                  :disabled="updating || state.busy || fetching.suppliers"
                  :loading="fetching.suppliers"
                >
                  <template #option="{ supplier_name, supplier_address }">
                    <div class="d-flex flex-column">
                      <strong>{{ supplier_name }}</strong>
                      <span>{{ supplier_address }}</span>
                    </div>
                  </template>
                  <template #no-options="">
                    no available supplier
                  </template>
                </v-select>
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <ValidationProvider
              #default="{ errors }"
              name="brand"
              :vid="vBRAND"
              rules="required"
            >
              <b-form-group>
                <label for="item_brand">
                  <strong>
                    Brand
                  </strong>
                </label>
                <v-select
                  id="item_brand"
                  v-model="selected.brand"
                  type="text"
                  label="brand_name"
                  placeholder="search brand here"
                  :options="brands"
                  :class="[errors.length > 0 ? 'is-invalid' : null]"
                  :disabled="updating || state.busy || fetching.brands"
                  :loading="fetching.brands"
                >
                  <template #option="{ brand_name }">
                    <div class="d-flex flex-column">
                      <strong>{{ brand_name }}</strong>
                    </div>
                  </template>
                  <template #no-options="">
                    no available brand
                  </template>
                </v-select>
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-row>

              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="item_type_name">
                    <strong>
                      Type
                    </strong>
                  </label>
                  <b-input
                    id="item_type_name"
                    v-model="composition.type_name"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="item_category_name">
                    <strong>
                      Category
                    </strong>
                  </label>
                  <b-input
                    id="item_category_name"
                    v-model="composition.category_name"
                    disabled
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <ValidationProvider
              #default="{ errors }"
              name="serial number"
              :vid="vSERIALNUMBER"
              rules="required|max:100"
            >
              <b-form-group>
                <label for="item_serial_number">
                  <strong>
                    Serial Number
                  </strong>
                </label>
                <b-input
                  id="item_serial_number"
                  v-model="composition.serial_number"
                  type="text"
                  placeholder="enter serial number"
                  autocomplete="off"
                  :state="errors.length > 0 ? false : null"
                  :disabled="updating || state.busy"
                />
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <ValidationProvider
              #default="{ errors }"
              name="name"
              :vid="vITEMNAME"
              rules="required|max:100"
            >
              <b-form-group>
                <label for="item_name">
                  <strong>
                    Name
                  </strong>
                </label>
                <b-input
                  id="item_name"
                  v-model="composition.item_name"
                  type="text"
                  placeholder="enter name"
                  autocomplete="off"
                  :state="errors.length > 0 ? false : null"
                  :disabled="updating || state.busy"
                />
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          />
          <b-col
            cols="12"
            md="4"
          >
            <ValidationProvider
              #default="{ errors }"
              name="mac address"
              :vid="vMACADDRESS"
              rules="max:50"
            >
              <b-form-group>
                <label for="item_mac_address">
                  <strong>
                    MAC Address
                  </strong>
                </label>
                <b-input
                  id="item_mac_address"
                  v-model="composition.mac_address"
                  type="text"
                  placeholder="enter serial number"
                  autocomplete="off"
                  :state="errors.length > 0 ? false : null"
                  :disabled="updating || state.busy"
                />
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <ValidationProvider
              #default="{ errors }"
              name="name"
              :vid="vDEVICENAME"
              rules="required|max:100"
            >
              <b-form-group>
                <label for="device_name">
                  <strong>
                    Device Name
                  </strong>
                </label>
                <b-input
                  id="device_name"
                  v-model="composition.device_name"
                  type="text"
                  placeholder="enter device name"
                  autocomplete="off"
                  :state="errors.length > 0 ? false : null"
                  :disabled="updating || state.busy"
                />
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col
            cols="12"
          >
            <ValidationProvider
              #default="{ errors }"
              name="specifications"
              :vid="vSPECIFICATIONS"
              rules="max:1000"
            >
              <b-form-group>
                <label for="item_specifications">
                  <strong>
                    Specifications
                  </strong>
                </label>
                <b-textarea
                  id="item_specifications"
                  v-model="composition.specifications"
                  rows="3"
                  max-rows="12"
                  placeholder="enter specifications"
                  :state="errors.length > 0 ? false : null"
                  :disabled="updating || state.busy"
                />
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col
            v-if="state.editing"
            cols="12"
          >
            <div class="d-flex justify-content-between">
              <div>
                <div
                  class="form-group"
                >
                  <b-form-checkbox
                    id="is_active"
                    v-model="composition.active"
                    name="is_active"
                    value="1"
                    unchecked-value="0"
                    :disabled="state.busy"
                    switch
                  >
                    Is Active?
                  </b-form-checkbox>
                </div>
              </div>
              <div class="mb-3">
                <b-overlay
                  :show="updating"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-button
                    variant="success"
                    :disabled="updating || state.busy"
                    @click="onValidateFormItem"
                  >
                    Update
                  </b-button>
                </b-overlay>
              </div>
            </div>
          </b-col>

        </b-row>
      </form>
    </ValidationObserver>

  </b-container>
</template>
<script>
import { AxiosError, AdminItemService, AdminHistoryService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'Item',
  mixins: [formatter],
  props: {
    template: {
      type: [Number, Object],
      default: () => {
        return 0
      }
    },
    position: {
      type: Number,
      default: () => {
        return 0
      }
    },
    global: {
      type: Object,
      default: () => {
        return {
          item: null,
          purchase_date: null,
          supplier: null,
          brand: null
        }
      }
    },
    fetching: {
      type: Object,
      default: () => {
        return {
          suppliers: false,
          brands: false
        }
      }
    },
    state: {
      type: Object,
      default: () => {
        return {
          editing: false,
          busy: false
        }
      }
    },
    suppliers: {
      type: Array,
      default: () => {
        return []
      }
    },
    brands: {
      type: Array,
      default: () => {
        return []
      }
    },
    composition: {
      type: Object,
      default: () => {
        return {
          id: null,
          type: null,
          type_name: null,
          category: null,
          category_name: null,
          supplier: null,
          supplier_name: null,
          brand: null,
          brand_name: null,
          item_name: null,
          serial_number: null,
          purchase_date: null,
          original_cost: 0.00,
          specifications: null,
          is_parent: 1,
          is_required: 1,
          is_removed: 0,
          active: 1
        }
      }
    }
  },
  data () {
    const supplier = { id: this.composition.supplier, supplier_name: this.composition.supplier_name, supplier_address: null }
    const brand = { id: this.composition.brand, brand_name: this.composition.brand_name }

    return {
      updating: false,
      selected: {
        supplier: this.state.editing ? supplier : null,
        brand: this.state.editing ? brand : null
      },
      item: {
        id: this.composition.id,
        type: this.composition.type,
        type_name: this.composition.type_name,
        category: this.composition.category,
        category_name: this.composition.category_name,
        supplier: this.composition.supplier,
        supplier_name: this.composition.supplier_name,
        brand: this.composition.brand,
        brand_name: this.composition.brand_name,
        item_name: this.composition.item_name,
        serial_number: this.composition.serial_number,
        purchase_date: this.composition.purchase_date,
        original_cost: this.composition.original_cost,
        specifications: this.composition.specifications,
        device_name: this.composition.device_name,
        mac_address: this.composition.mac_address,
        is_parent: this.composition.is_parent,
        is_required: this.composition.is_required,
        is_removed: this.composition.is_removed,
        active: this.composition.active,
        template: this.template
      },
      tableItemDetailHistories: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: 'updated_at',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        fields: [
          'index',
          { key: 'updated_at', sortable: true, formatter: this.dateTimeLongFormatter },
          { key: 'item', label: 'parent' },
          { key: 'item_detail' },
          { key: 'supplier_name', label: 'supplier' },
          { key: 'status_name', label: 'status' },
          { key: 'action' },
          { key: 'updated_by_name', label: 'action by' }
        ]
      }
    }
  },
  computed: {
    vSUPPLIER () {
      return this.composition.id ? 'supplier' : `compositions.${this.position}.supplier`
    },
    vBRAND () {
      return this.composition.id ? 'brand' : `compositions.${this.position}.brand`
    },
    vSERIALNUMBER () {
      return this.composition.id ? 'serial_number' : `compositions.${this.position}.serial_number`
    },
    vITEMNAME () {
      return this.composition.id ? 'item_name' : `compositions.${this.position}.item_name`
    },
    vPURCHASEDATE () {
      return this.composition.id ? 'purchase_date' : `compositions.${this.position}.purchase_date`
    },
    vORIGINALCOST () {
      return this.composition.id ? 'original_cost' : `compositions.${this.position}.original_cost`
    },
    vSPECIFICATIONS () {
      return this.composition.id ? 'specifications' : `compositions.${this.position}.specifications`
    },
    vDEVICENAME () {
      return this.composition.id ? 'device_name' : `compositions.${this.position}.device_name`
    },
    vMACADDRESS () {
      return this.composition.id ? 'mac_address' : `compositions.${this.position}.mac_address`
    }
  },
  watch: {

    'global.purchase_date' (purchaseDate) {
      this.composition.purchase_date = purchaseDate
    },

    'global.supplier' (supplier) {
      // this.composition.supplier = supplier?.id || null
      // this.composition.supplier_name = supplier?.supplier_name || null
      this.selected.supplier = supplier
    },

    'global.brand' (brand) {
      // this.composition.brand = brand?.id || null
      // this.composition.brand_name = brand?.brand_name || null
      this.selected.brand = brand
    },

    'selected.supplier' (supplier) {
      this.composition.supplier = supplier?.id || null
      this.composition.supplier_name = supplier?.supplier_name || null
    },

    'selected.brand' (brand) {
      this.composition.brand = brand?.id || null
      this.composition.brand_name = brand?.brand_name || null
    }

  },
  methods: {

    async myItemDetailHistoryProvider (ctx) {
      return await AdminHistoryService.getItemDetailHistoriesOfItem(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          item: this.item.id
        })
      ).then(({ data }) => {
        this.tableItemDetailHistories.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    onValidateFormItem () {
      this.$refs.formItem.validate().then(success => {
        if (success && this.state.editing) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to save changes?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.updating = true
              return this.onUpdate()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onUpdate () {
      this.composition.template = this.template
      return new Promise(resolve => {
        AdminItemService.put(this.composition).then(({ data }) => {
          this.updating = false
          this.item = this.dataToComposition(data.item, 1)
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.updated()
          })
        }).catch(error => {
          this.updating = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formItem.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onItemDetailShowHistories () {
      this.$bvModal.show('modal-histories-of-item-details')
    },

    showItemDetails () {
      this.$emit('showItemDetails', this.item)
    },

    showHistories () {
      this.$emit('showHistories', this.item)
    },

    updated () {
      this.$emit('updated', this.item, this.composition)
    }
  }
}
</script>
<style>
  #modal-histories-of-item-details .modal-xl {
    width:100vw;
    max-width: none;
    min-height: 100vh;
    margin: 0
  }
  #modal-histories-of-item-details .modal-content {
    min-height: 100vh;
  }
</style>
